import React, { Component } from 'react';
import './create-tickets.css'

//NPM
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Layout from '../../components/layout';
import styled from 'styled-components'

//PARSE
import { CreateTicketTypes } from '../../services/parse-bridge'

const TicketNumber = styled.h1`
    background-color: #fc444e;
    color: white;
    display: block;
    padding: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
`

const CreateContainer = styled.div`
    display: grid;
    padding: 30px;
    border: 1px dashed #e6e6e6;
    border-radius: 10px;
    background-color: white;
    // box-shadow: 0 5px 6px #ececec;
    max-width: 700px;
    margin: 10px auto;

`
const TicketLabel = styled.label`
    text-transform: uppercase;
    font-family: Avenir-Black;
    color: #5D323B;
    font-size: 12px;
`
const TicketInput = styled.input`
    border: 1px solid #f5f5f5;
    border-radius: 10px;    
    box-shadow: none;
    padding: 10px;
    font-size: 18px;
    font-family: Avenir-Roman;
    -webkit-appearance: none;
    margin-bottom: 10px;

    &:focus {
        outline: none;
    }
`
class CreateTickets extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ticketObjects: [],
            ticketType: "",
            ticketPrice: "",
            ticketCount: "",
            currentCount: 1,
            showConfirm: false,
            pumpName: "",
            pumpId: "",
            showTicketForm: true

        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.completeCreation = this.completeCreation.bind(this)
    }

    componentDidMount() {
        
        console.log(this.props.location.search)
        console.log("This is the pump: ", this.props.location.state.pump);
        this.setState({pumpName: this.props.location.state.pump.name });
        let searchId = String(this.props.location.search)
        let id = searchId.substr(1,10)
        this.setState({ pumpId: this.props.location.state.pump.id })
    }

    handleSubmit(event) {
        event.preventDefault()

        //MAKE SURE ALL FIELDS FILLED
        if( this.state.ticketType == "" | this.state.ticketPrice == "" | this.state.ticketCount == "") {
            alert("Please fill all fields!")
            return
        }
        
        let obj = {
            ticketType: this.state.ticketType,
            ticketCount: this.state.ticketCount,
            ticketPrice: this.state.ticketPrice,
        }

        let newObj = [...this.state.ticketObjects, obj]
        this.setState({ ticketObjects: newObj, showTicketForm: false},()=>this.checkButtonState())

        console.log(obj)

        //Reset form field values
        this.setState({
            ticketType: "",
            ticketCount: "",
            ticketPrice: "",
            currentCount: this.state.currentCount + 1
        })

        
    }

    updateInput = e => {

        this.setState({
            [e.target.name]: e.target.value
        });
    }

    completeCreation() {
        CreateTicketTypes(this.state.ticketObjects, this.state.pumpId, this.state.pumpName);
    }

    removeTicket(index) {
        let tics = this.state.ticketObjects
        tics.splice(index, 1)

        this.setState({ ticketObjects: tics, currentCount: this.state.currentCount-1 },()=>this.checkButtonState())
        console.log(tics)
    }

    checkButtonState() {
        let objs = this.state.ticketObjects
        console.log(objs.length)
        if (this.state.ticketObjects.length < 1) {
            this.setState({ showConfirm: false })
        }else {
            console.log('more than 1')
            this.setState({ showConfirm: true })
        }
    }

    render() {
        return (
            <Layout>
                <div className="CreateTickets">
                    <h1>Create Tickets</h1>
                    <p>Create up to 5 ticket types below. As you do, they will appear below this area. Once you are finished, click 'Done' or 'Create More' to add another ticket type.</p>
                    <div className="createdTickets">
                        <ul>
                            {this.state.ticketObjects.map((tic, index) => (
                                <li className="Fade">{tic.ticketCount} {tic.ticketType} tickets at ${tic.ticketPrice} <button onClick={()=>this.removeTicket(index)} className="remove-button">Remove</button></li>
                            ))}
                        </ul>
                    </div>
                    <hr style={{opacity: "0.3"}}/>

                    {
                        this.state.showTicketForm ?
                        <form onSubmit={this.handleSubmit}>
                        <div className="ticket-info">
                            <h2>Ticket #{this.state.currentCount}</h2>
                            <div>
                                <label for="ticketType">Ticket name</label>
                                <input type="text" placeholder="Early Bird, General, etc" name="ticketType" id="ticketType" value={this.state.ticketType} onChange={this.updateInput} />
                            </div>
                            <div>
                                <label for="ticketType">Quantity available</label>
                                <input type="number" placeholder="100" name="ticketCount" id="ticketCount" value={this.state.ticketCount} onChange={this.updateInput} />
                            </div>
                            <div>
                                <label for="ticketType">Price (in TTD)</label>
                                <input type="number" placeholder="250" name="ticketPrice" id="ticketPrice" value={this.state.ticketPrice} onChange={this.updateInput} />
                            </div>
                            <button name="ticket1" className="text-button" type="submit">+ Create Ticket</button>
                            
                        </div>
                        {/* <button type="submit">Create Tickets!</button> */}
                    </form>
                    :
                    null
                    }
                    {this.state.showTicketForm ? null : 
                        <div className="actionGroup">
                            <button onClick={()=>this.setState({showTicketForm: true})} className="createAnother Fade" name="createAnother">Create More</button>
                            <button onClick={this.completeCreation} className="doneCreating Fade" name="doneCreating">Done</button>
                        </div>
                    }
                    {/* {this.state.showConfirm ? <button className="confirm-button Fade" onClick={this.completeCreation}>Confirm Tickets</button> : null } */}
                    
                </div>
            </Layout>
        );
    }
}

export default CreateTickets;